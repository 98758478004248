










































































































































































import { ref, computed, watch } from '@vue/composition-api';
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { extend } from 'vee-validate';

extend('phone', {
  validate: value => {
    const phoneNumber = parsePhoneNumberFromString(value);
    return phoneNumber ? phoneNumber.isValid() : false;
  },
  message: 'Please enter a valid phone number'
});

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isLoadSaveBtn: {
      type: Boolean,
      default: false
    },
    basicUserInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const dialogVisible = computed({
      get: () => props.value,
      set: value => ctx.emit('input', value)
    });

    const isLoading: any = computed({
      get: () => props.isLoadSaveBtn,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const userInfo: any = computed({
      get: () => props.basicUserInfo,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const isFilled = computed(() => {
      const { email, firstName, lastName } = userInfo.value;
      return !!email && !!firstName && !!lastName;
    });

    const showPasswordField = ref(false);
    const showPassword = ref(false);
    const password = ref('');
    const showForgotPasswordDialog = ref(false);
    const confirmEmail = ref('');
    const oneTimePassword = ref('');
    const otpStatus = ref('');
    const newCodeSent = ref(false);

    const statusMessage = computed(() => {
      if (newCodeSent.value) {
        return 'New code sent. Check your messages.';
      }
      if (otpStatus.value === 'success') {
        return 'Code accepted. Logging in...';
      }
      if (otpStatus.value === 'error') {
        return 'Incorrect code. Please try again.';
      }
      return '';
    });

    const statusColor = computed(() => {
      if (newCodeSent.value) {
        return 'info';
      }
      if (otpStatus.value === 'success') {
        return 'success';
      }
      if (otpStatus.value === 'error') {
        return 'error';
      }
      return '';
    });

    const statusIcon = computed(() => {
      if (newCodeSent.value) {
        return 'mdi-message-text';
      }
      if (otpStatus.value === 'success') {
        return 'mdi-check-circle';
      }
      if (otpStatus.value === 'error') {
        return 'mdi-alert-circle';
      }
      return '';
    });

    const validateOTP = async (otp: string) => {
      return new Promise<boolean>(resolve => {
        setTimeout(() => {
          resolve(otp === '123456'); // Example: OTP is valid if it's '123456'
        }, 500); // Simulate API call delay
      });
    };

    const refreshOTP = () => {
      console.log('Refreshing OTP');
      oneTimePassword.value = '';
      otpStatus.value = '';
      newCodeSent.value = true;

      // Reset newCodeSent after 5 seconds
      setTimeout(() => {
        newCodeSent.value = false;
      }, 5000);
    };

    watch(oneTimePassword, async newValue => {
      newCodeSent.value = false;
      if (newValue.length === 6) {
        // Assuming OTP is 6 digits
        otpStatus.value = 'validating';
        const isValid = await validateOTP(newValue);
        otpStatus.value = isValid ? 'success' : 'error';

        if (isValid) {
          // Simulate login after a short delay
          setTimeout(() => {
            // Implement your login logic here
            console.log('Logging in...');
          }, 2000);
        }
      } else {
        otpStatus.value = '';
      }
    });

    const forgotPassword = () => {
      showForgotPasswordDialog.value = true;
    };

    const sendResetInstructions = () => {
      if (confirmEmail.value === userInfo.value.email) {
        // Implement password reset logic here
        console.log('Sending password reset instructions to:', confirmEmail.value);
        ctx.emit('send-reset-instructions', confirmEmail.value);
        showForgotPasswordDialog.value = false;
      } else {
        // Handle email mismatch error
        console.error('Email does not match');
      }
    };

    const handlePasswordAction = () => {
      if (showPasswordField.value) {
        // Perform login action
        console.log('Logging in with password:', password.value);
        ctx.emit('login-with-password', password.value);
      } else {
        // Show password field
        showPasswordField.value = true;
      }
    };

    const hidePasswordField = () => {
      showPasswordField.value = false;
      password.value = ''; // Clear the password field when hiding
    };

    const contactInfo = ref('');

    const isEmail = computed(() => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(contactInfo.value);
    });

    const phoneNumber = computed(() => {
      if (!isEmail.value) {
        let numberToParse = contactInfo.value;
        if (!numberToParse.startsWith('+')) {
          numberToParse = `+1${numberToParse.replace(/^1/, '')}`;
        }
        return parsePhoneNumberFromString(numberToParse);
      }
      return null;
    });

    const isPhone = computed(() => {
      return phoneNumber.value?.isValid() || false;
    });

    const countryCallingCode = computed(() => {
      if (isPhone.value) {
        return phoneNumber.value?.countryCallingCode || '1';
      }
      return '';
    });

    const contactType = computed(() => {
      if (isEmail.value) return 'email';
      if (isPhone.value) return 'tel';
      return 'text';
    });

    const contactLabel = computed(() => {
      if (isEmail.value) return 'Email';
      if (isPhone.value) return 'Phone';
      return 'Phone or Email';
    });

    const contactPlaceholder = computed(() => {
      if (isEmail.value) return 'Enter your email';
      if (isPhone.value) return 'Enter your phone number';
      return 'Enter your phone or email';
    });

    const contactRules = computed(() => {
      if (isEmail.value) return 'required|email';
      if (isPhone.value) return 'required|phone';
      return 'required';
    });

    const formatPhoneNumber = () => {
      if (!isEmail.value) {
        let numberToFormat = contactInfo.value;
        if (!numberToFormat.startsWith('+')) {
          numberToFormat = `+1${numberToFormat.replace(/^1/, '')}`;
        }
        const formatter = new AsYouType();
        contactInfo.value = formatter.input(numberToFormat);
      }
    };

    return {
      dialogVisible,
      userInfo,
      isFilled,
      isLoading,
      showPasswordField,
      password,
      showPassword,
      forgotPassword,
      showForgotPasswordDialog,
      confirmEmail,
      sendResetInstructions,
      handlePasswordAction,
      hidePasswordField,
      oneTimePassword,
      otpStatus,
      statusMessage,
      statusColor,
      statusIcon,
      refreshOTP,
      contactInfo,
      contactType,
      contactLabel,
      contactPlaceholder,
      contactRules,
      formatPhoneNumber,
      isPhone,
      countryCallingCode
    };
  }
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('validation-observer',[_c('v-card',{staticClass:"pa-6"},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"h1 font-weight-bold"},[_c('v-icon',{attrs:{"left":"","x-large":""}},[_vm._v("mdi-telegram")]),_vm._v("Signup or Login ")],1)]),_c('v-container',{staticClass:"mt-4"},[_c('validation-provider',{attrs:{"rules":_vm.contactRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.contactLabel,"rounded":"","outlined":"","x-large":"","placeholder":_vm.contactPlaceholder,"type":_vm.contactType,"error-messages":errors},on:{"input":_vm.formatPhoneNumber},scopedSlots:_vm._u([(_vm.isPhone)?{key:"prepend-inner",fn:function(){return [_c('span',{staticClass:"country-code mr-2"},[_vm._v("+"+_vm._s(_vm.countryCallingCode))])]},proxy:true}:null],null,true),model:{value:(_vm.contactInfo),callback:function ($$v) {_vm.contactInfo=$$v},expression:"contactInfo"}})]}}])})],1),_c('v-card-actions',{staticClass:"my-2 d-flex justify-center mt-4"},[_c('v-btn',{staticClass:"font-weight-bold white--text text--white px-6",attrs:{"color":"black","x-large":"","depressed":"","rounded":"","loading":_vm.isLoading,"disabled":_vm.invalid},on:{"click":function($event){(_vm.userInfo.filled = true), _vm.$emit('submit')}}},[_vm._v("Continue")])],1),_c('v-divider',{staticClass:"my-10"}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"One Time Password","rounded":"","outlined":"","x-large":"","placeholder":"Enter your one time password","error-messages":errors},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"refresh-btn",attrs:{"icon":""},on:{"click":_vm.refreshOTP}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,true)},[_c('span',[_vm._v("Get Another Code")])])]},proxy:true}],null,true),model:{value:(_vm.oneTimePassword),callback:function ($$v) {_vm.oneTimePassword=$$v},expression:"oneTimePassword"}})]}}])}),(_vm.statusMessage)?_c('div',{staticClass:"mt-2 mb-4 d-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.statusColor}},[_vm._v(" "+_vm._s(_vm.statusIcon)+" ")]),_c('span',{class:(_vm.statusColor + "--text")},[_vm._v(" "+_vm._s(_vm.statusMessage)+" ")])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center mb-4"},[_c('h2',[_vm._v("or")])]),(_vm.showPasswordField)?_c('div',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","type":_vm.showPassword ? 'text' : 'password',"rounded":"","outlined":"","x-large":"","placeholder":"Enter your password","error-messages":errors,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,false,3673128071)}),_c('div',{staticClass:"d-flex justify-space-between align-center password-actions"},[_c('v-btn',{staticClass:"password-action-btn",attrs:{"text":"","small":""},on:{"click":_vm.hidePasswordField}},[_vm._v(" Hide password field ")]),_c('v-btn',{staticClass:"password-action-btn font-weight-bold",attrs:{"text":"","small":""},on:{"click":_vm.forgotPassword}},[_vm._v(" Forgot Password? ")])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-4"},[_c('v-btn',{attrs:{"x-large":"","rounded":"","dark":"","width":"100%","depressed":""},on:{"click":_vm.handlePasswordAction}},[_vm._v(" "+_vm._s(_vm.showPasswordField ? 'Login' : 'Enter Password')+" ")])],1),_c('v-dialog',{attrs:{"max-width":"450px"},model:{value:(_vm.showForgotPasswordDialog),callback:function ($$v) {_vm.showForgotPasswordDialog=$$v},expression:"showForgotPasswordDialog"}},[_c('v-card',{staticClass:"pa-6"},[_c('v-card-title',{staticClass:"h1 font-weight-bold"},[_vm._v("Reset Password")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Confirm Email","type":"email","rounded":"","outlined":"","x-large":"","placeholder":"Enter your email","rules":[
                function (v) { return !!v || 'Email is required'; },
                function (v) { return v === _vm.userInfo.email || 'Email does not match'; }
              ]},model:{value:(_vm.confirmEmail),callback:function ($$v) {_vm.confirmEmail=$$v},expression:"confirmEmail"}})],1),_c('v-card-actions',{staticClass:"px-0 pb-0"},[_c('v-btn',{attrs:{"color":"grey darken-1","text":"","rounded":""},on:{"click":function($event){_vm.showForgotPasswordDialog = false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold white--text text--white send-instructions-btn",attrs:{"color":"black","depressed":"","rounded":""},on:{"click":_vm.sendResetInstructions}},[_vm._v(" Send Instructions ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }